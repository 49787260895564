import React from 'react';
import ContactCard from 'components/ContactCard/ContactCard';
import './ContactCardGroup.scss';

const ContactCardGroup = ({ data }) => (
  <div className="contact-card-group">
    <h2 className="contact-card-group__title">{data.title}</h2>
    <h3 className="contact-card-group__subtitle">{data.subtitle}</h3>
    <div>
    {data.contactCards.map((card, i) => <ContactCard data={card} key={i} showTitle={data.contactCards.length > 1} />)}
    </div>
  </div>
);

export default ContactCardGroup;