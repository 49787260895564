import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import AfterHeroSection from 'components/AfterHeroSection/AfterHeroSection';
import ContactInfoContainer from 'components/ContactInfoContainer/ContactInfoContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';

const ContactPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.contactYaml.title} />
      <Hero data={data.contactYaml.hero} />
      <AfterHeroSection data={data.contactYaml.afterHeroSection} />
      <ContactInfoContainer data={data.contactYaml.contactInfoContainer} />
      <CTAForm />
      <ZipGateContainer location={location} />
      <ScrollToTopButton />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery {
    contactYaml {
      id
      title
      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
      }

      afterHeroSection {
        title
        description
      }

      contactInfoContainer {
        contactCardContainer {
          contactCards {
            title
            subtitle
            contactCards {
              title
              phone {
                title
                link
              }
              email
              hours
            }
          }
        }

        locationCardContainer {
          title
          locationCards {
            officeName
            addressLine1
            addressLine2
            phone {
              title
              link
            }
            email
          }
        }
      }
    }
  }
`;
