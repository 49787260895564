import React from 'react';
import ContactCardGroup from 'components/ContactCardGroup/ContactCardGroup';
import './ContactCardContainer.scss';

const ContactCards = ({ cards }) => {
  if (cards) {
    return cards.map((card, i) => <ContactCardGroup data={card} key={i} />);
  }

  return null;
}

const ContactCardContainer = ({ data }) => (
  <div className="col-sm-12 col-md-10 offset-md-1 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2">
    <div className="contact-card-container">
      <ContactCards cards={data.contactCards} />
    </div>
  </div>
);

export default ContactCardContainer;