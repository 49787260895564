import React from 'react';
import ContactCardContainer from 'components/ContactCardContainer/ContactCardContainer';
import LocationCardContainer from 'components/LocationCardContainer/LocationCardContainer';
import './ContactInfoContainer.scss';

const ContactInfoContainer = ({ data }) => (
  <div className="contact-info-container row">
    <ContactCardContainer data={data.contactCardContainer} />
    <LocationCardContainer data={data.locationCardContainer} />
  </div>
);

export default ContactInfoContainer;