import React from 'react';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import LocationCard from 'components/LocationCard/LocationCard';
import './LocationCardContainer.scss';

const LocationCards = ({ cards }) => {
  if (cards) {
    return cards.map((card, i) => <LocationCard data={card} key={i} />);
  }

  return null;
}

const LocationCardContainer = ({ data }) => (
  <div className="col-sm-12 col-md-10 offset-md-1 col-lg-5 offset-lg-0 col-xl-4">
    <div className="location-card-container">
      <h2 className="location-card-container__title">{data.title}</h2>
      <LocationCards cards={data.locationCards} />
      <SeparatorWithGradient className="location-card-container__separator"/>
    </div>
  </div>
);

export default LocationCardContainer;