import React from 'react';
import { RegularLink } from 'components/Shared/Link/Link';
import './ContactCard.scss';

const ContactCard = ({ data, showTitle }) => (
  <div className="contact-card">
    {showTitle && <h3 className="contact-card__title">{data.title}</h3>}
    <div className="contact-card__phone">
      <span className="contact-card__phone__title">Phone:</span>
      <RegularLink className="contact-card__phone__text" to={`tel:${data.phone.link}`}>
        <div dangerouslySetInnerHTML={{__html: data.phone.title}} />
      </RegularLink>
    </div>
    <div className="contact-card__email">
      <span className="contact-card__email__title">Email:</span>
      <RegularLink className="contact-card__email__text" to={`mailto:${data.email}`}>
        {data.email}
      </RegularLink>
    </div>
    <div className="contact-card__hours">
      <span className="contact-card__hours__title">Hours:</span>
      <span className="contact-card__hours__text">{data.hours}</span>
    </div>
  </div>
);

export default ContactCard;