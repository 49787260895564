import React from 'react';
import { GetAfreeQuoteButton } from 'components/Shared/Button/Button';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import CTAPopUpContext from 'components/Shared/CTAPopUpContext';
import { MetricsElement } from 'react-metrics';
import './AfterHeroSection.scss';

const CallToActionLink = ({ data }) => {
  if (data) {
    return (
      <CTAPopUpContext.Consumer>
        {({ togglePopUp }) => (
          <MetricsElement>
            <GetAfreeQuoteButton 
              data-metrics-event-name='afterhero_cta_click'
              data-metrics-label={data.text}
              onClick={() => togglePopUp(true)}
              to={data.url}
            >
              {data.text}
            </GetAfreeQuoteButton>
          </MetricsElement>
        )}
      </CTAPopUpContext.Consumer>
    );
  }
  return null;
};

const AfterHeroSection = ({ data }) => (
  <div className="after-hero">
    <ContentBox>
      <h1 className="after-hero__title">{data.title}</h1>
      { data.subtitle ? (
        <h2 className="after-hero__subtitle">{data.subtitle}</h2>
      ) : '' }
      <hr/>
      <div className="after-hero__text"
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      />
      <CallToActionLink data={data.callToActionButton} />
    </ContentBox>
  </div>
);

export default AfterHeroSection;
